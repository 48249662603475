<template>
  <div>
    <!-- New Filter Mode selector -->
    <v-select
      class="ma-4"
      label="Filter Mode"
      v-model="filterMode"
      :items="[
        { text: 'Process Type', value: 'process' },
        { text: 'Product', value: 'product' }
      ]"
      hide-details
      dense
      @change="filter"
    />

    <!-- Process Type filter shown when filterMode is "process" -->
    <v-autocomplete
      v-if="filterMode === 'process'"
      class="ma-4"
      label="Process Type"
      placeholder="Filter by process type"
      hide-details
      dense
      v-model="selectedProcessType"
      :items="availableProcessTypes"
      item-text="name"
      item-value="id"
      @change="filter"
    />

    <!-- Product filters shown when filterMode is "product" -->
    <div v-if="filterMode === 'product'">
      <v-autocomplete
        class="ma-4"
        label="Input Products"
        placeholder="Filter by input products"
        multiple
        dense
        hide-details
        v-model="selectedInputProducts"
        :items="allInputProducts"
        @change="filter"
      />

      <v-autocomplete
        class="ma-4"
        label="Output Products"
        placeholder="Filter by output products"
        multiple
        dense
        hide-details
        v-model="selectedOutputProducts"
        :items="allOutputProducts"
        @change="filter"
      />
    </div>

    <v-data-table
      class="inventory-details"
      :headers="headers"
      :items="flatData"
      item-key="name"
    >
      <template v-if="!isMobile" v-slot:header="{}">
        <thead>
          <tr>
            <th></th>
            <th :colspan="mode.inputs.expanded ? inputProducts.size + 1 : 1" :class="mode.inputs.expanded ? 'text-center' : ''">
              {{ $vuetify.lang.t("$vuetify.summary.inputs") }}
              <v-icon v-ripple @click="() => { mode.inputs.expanded = !mode.inputs.expanded; filter(); }">
                {{ mode.inputs.expanded ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline' }}
              </v-icon>
            </th>
            <th :colspan="mode.outputs.expanded ? outputProducts.size + 1 : 1" :class="mode.outputs.expanded ? 'text-center' : ''">
              {{ $vuetify.lang.t("$vuetify.summary.outputs") }}
              <v-icon v-ripple @click="() => { mode.outputs.expanded = !mode.outputs.expanded; filter(); }">
                {{ mode.outputs.expanded ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline' }}
              </v-icon>
            </th>
            <th :colspan="2" class="text-center">
              {{ $vuetify.lang.t("$vuetify.summary.summary") }}
            </th>
            <th></th>
          </tr>
        </thead>
      </template>

      <template v-slot:header.group="{}">
        <v-select
          :label="$vuetify.lang.t('$vuetify.summary.groupBy')"
          @change="filter"
          dense
          hide-details
          v-model="groupBy"
          :items="[
            { text: $vuetify.lang.t('$vuetify.summary.process'), value: 'process' },
            { text: $vuetify.lang.t('$vuetify.base.date'), value: 'date' }
          ]"
        />
      </template>

      <template v-if="groupBy == 'process'" v-slot:item.group="{ item }">
        <a target="_blank" :href="`https://prod.${domain}.com/factory/process/${item.group}`">
          {{ item.code }}
        </a>
      </template>

      <template v-slot:item.inputTotal="{ item }">
        <div class="totalCell">{{ item.inputTotal | formatNumber }}</div>
      </template>

      <template v-slot:item.outputTotal="{ item }">
        <div class="totalCell">{{ item.outputTotal | formatNumber }}</div>
      </template>

      <template v-slot:item.delta="{ item }">
        <div class="totalCell">
          {{ item.delta | formatNumber }} ({{ item.outputTotal / item.inputTotal | formatPercent(2) }})
        </div>
      </template>

      <template v-slot:item.totalProcessTime="{ item }">
        <div class="totalCell">
          <span v-if="item.totalProcessTime">
            {{ (item.totalProcessTime / 60).toFixed(1) }} {{ $vuetify.lang.t("$vuetify.summary.hours") }}
          </span>
          <span v-else> N/A </span>
          <div class="text-caption">
            {{ item.firstInputTime | formatDateTime }} - {{ item.lastOutputTime | formatDateTime }}
          </div>
        </div>
      </template>

      <template v-slot:body.append="{}">
        <tr>
          <th>{{ $vuetify.lang.t("$vuetify.base.total") }}</th>
          <template v-if="mode.inputs.expanded">
            <th v-for="(input, index) in inputProducts" :key="index">
              {{ inProductTotals[`in-${input}`] | formatNumber }}
            </th>
          </template>
          <th>{{ totalInputs | formatNumber }}</th>

          <template v-if="mode.outputs.expanded">
            <th v-for="(output, index) in outputProducts" :key="index">
              {{ outProductTotals[`out-${output}`] | formatNumber }}
              ({{ outProductTotals[`out-${output}`] / totalInputs | formatPercent(2) }})
            </th>
          </template>

          <th>{{ totalOutputs | formatNumber }}</th>
          <th>{{ totalDelta | formatNumber }} ({{ totalOutputs / totalInputs | formatPercent(2) }})</th>
          <th>
            <span v-if="totalProcessTime">
              {{ (totalProcessTime / 60).toFixed(1) }} {{ $vuetify.lang.t("$vuetify.summary.hours") }}
            </span>
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    value: {
      type: Array,
      required: false,
    },
    eventList: {
      type: Array,
      required: true,
      default: () => [],
    },
    domain: {
      type: String,
      required: false,
      default: "localhost",
    },
    date: {
      type: Date,
    },
  },
  computed: {
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
    ...mapGetters("processes", ["processTypes"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    // Build full list of distinct input products from eventList
    allInputProducts() {
      const set = new Set();
      this.eventList.forEach((e) => {
        if (e.type === "inputs") {
          set.add(e.product);
        }
      });
      return Array.from(set);
    },
    // Build full list of distinct output products from eventList
    allOutputProducts() {
      const set = new Set();
      this.eventList.forEach((e) => {
        if (e.type === "outputs") {
          set.add(e.product);
        }
      });
      return Array.from(set);
    },
  },
  data() {
    return {
      filterMode: "process", // "process" or "product"
      headers: [],
      summary: null,
      filteredEventList: [],
      selectedProcessType: null,
      availableProcessTypes: [],
      flatData: [],
      inputProducts: [],
      outputProducts: [],
      inProductTotals: {},
      outProductTotals: {},
      groupBy: "process",
      groupKey2Code: {},
      totalInputs: 0,
      totalOutputs: 0,
      totalDelta: 0,
      totalProcessTime: 0,
      mode: {
        inputs: { expanded: false },
        outputs: { expanded: false },
      },
      // For product filtering, selections start empty.
      selectedInputProducts: [],
      selectedOutputProducts: [],
    };
  },
  mounted() {
    // Set available process types from eventList.
    const availableProcessIds = new Set();
    this.eventList.forEach((e) => {
      availableProcessIds.add(e.processTypeId);
    });
    this.availableProcessTypes = this.processTypes.filter((p) =>
      availableProcessIds.has(p.id)
    );
    // Default process type selection if in process mode.
    if (this.filterMode === "process" && this.availableProcessTypes.length) {
      this.selectedProcessType = this.availableProcessTypes[0].id;
    }
    this.filter();
  },
  methods: {
    ...mapActions("processes", ["loadProcessTypes"]),
    getCostAdjustments(inventoryId, balanceDate) {
      return this.costAdjustmentService.getCostAdjustmentDetails(
        inventoryId,
        balanceDate
      );
    },
    filter() {
      if (this.filterMode === "process") {
        // Filter by process type.
        this.filteredEventList = this.eventList.filter(
          (e) => e.processTypeId == this.selectedProcessType
        );
      } else if (this.filterMode === "product") {
        // For each product filter, if the selection is empty then assume all products.
        const effectiveInput = this.selectedInputProducts.length
          ? this.selectedInputProducts
          : this.allInputProducts;
        const effectiveOutput = this.selectedOutputProducts.length
          ? this.selectedOutputProducts
          : this.allOutputProducts;
        // Merge both sets (union) to filter events that have a product in either list.
        const effectiveProducts = new Set([...effectiveInput, ...effectiveOutput]);
        this.filteredEventList = this.eventList.filter((e) =>
          effectiveProducts.has(e.product)
        );
      }
      this.summarize();
    },
    summarize() {
      // Reset mapping and totals.
      this.groupKey2Code = {};
      this.inProductTotals = {};
      this.outProductTotals = {};

      this.summary = this.filteredEventList.reduce(
        (group, row) => {
          let groupKey = this.groupBy === "process" ? row.processId : row.time.substring(0, 10);
          if (!group[groupKey]) {
            group[groupKey] = {
              inputs: {},
              outputs: {},
              inputTotal: 0,
              outputTotal: 0,
            };
            this.groupKey2Code[groupKey] = row.processCode;
          }

          if (row.type === "inputs") {
            const inputs = group[groupKey].inputs;
            const key = `in-${row.product}`;
            if (inputs[key]) {
              inputs[key].inventories.push(row);
              inputs[key].quantity += Math.abs(row.quantity);
              inputs[key].processTimes.push(row.time);
            } else {
              inputs[key] = {
                inventories: [row],
                quantity: Math.abs(row.quantity),
                processTimes: [row.time],
              };
            }
            group.inputProducts = group.inputProducts || new Set();
            group.inputProducts.add(row.product);
            this.inProductTotals[`in-${row.product}`] = (this.inProductTotals[`in-${row.product}`] || 0) + Math.abs(row.quantity);
          }
          if (row.type === "outputs") {
            const outputs = group[groupKey].outputs;
            const key = `out-${row.product}`;
            if (outputs[key]) {
              outputs[key].inventories.push(row);
              outputs[key].quantity += row.quantity;
              outputs[key].processTimes.push(row.time);
            } else {
              outputs[key] = {
                inventories: [row],
                quantity: row.quantity,
                processTimes: [row.time],
              };
            }
            group.outputProducts = group.outputProducts || new Set();
            group.outputProducts.add(row.product);
            this.outProductTotals[`out-${row.product}`] = (this.outProductTotals[`out-${row.product}`] || 0) + row.quantity;
          }
          return group;
        },
        { inputProducts: new Set(), outputProducts: new Set() }
      );

      this.calculateProcessTimeInHours(this.summary);
      this.flatten(this.summary);
    },
    flatten(summary) {
      let groupKeys = Object.keys(summary).filter(
        (k) => !["inputProducts", "outputProducts"].includes(k)
      );
      const data = [];
      const inputProducts = summary.inputProducts;
      const outputProducts = summary.outputProducts;

      // Build table headers.
      this.headers = [{ text: "Group", value: "group" }];
      if (this.mode.inputs.expanded) {
        inputProducts.forEach((product) => {
          this.headers.push({ text: product, value: `in-${product}` });
        });
      }
      this.headers.push({ text: this.$vuetify.lang.t("$vuetify.summary.inputTotal"), value: "inputTotal" });
      if (this.mode.outputs.expanded) {
        outputProducts.forEach((product) => {
          this.headers.push({ text: product, value: `out-${product}` });
        });
      }
      this.headers.push({ text: this.$vuetify.lang.t("$vuetify.summary.outputTotal"), value: "outputTotal" });
      this.headers.push({ text: this.$vuetify.lang.t("$vuetify.summary.delta"), value: "delta" });
      this.headers.push({ text: this.$vuetify.lang.t("$vuetify.summary.processTime"), value: "totalProcessTime" });

      let totalInputs = 0, totalOutputs = 0, totalDelta = 0, totalProcessTime = 0;

      groupKeys.forEach((groupKey) => {
        const row = { group: groupKey, code: this.groupKey2Code[groupKey] };
        let inputQuantity = 0, outputQuantity = 0;
        inputProducts.forEach((inputProduct) => {
          const key = `in-${inputProduct}`;
          const productVal = summary[groupKey].inputs[key];
          const quantity = productVal ? productVal.quantity : 0;
          inputQuantity += quantity;
          row[key] = this.$options.filters.formatNumber(quantity);
        });
        outputProducts.forEach((outputProduct) => {
          const key = `out-${outputProduct}`;
          const productVal = summary[groupKey].outputs[key];
          const quantity = productVal ? productVal.quantity : 0;
          outputQuantity += quantity;
          row[key] = `${this.$options.filters.formatNumber(quantity)} (${this.$options.filters.formatPercent(quantity / inputQuantity, 2)})`;
        });
        row["inputTotal"] = inputQuantity;
        row["outputTotal"] = outputQuantity;
        row["delta"] = outputQuantity - inputQuantity;
        row["totalProcessTime"] = summary[groupKey].totalProcessTime;
        row["firstInputTime"] = summary[groupKey].firstInputTime;
        row["lastOutputTime"] = summary[groupKey].lastOutputTime;

        totalInputs += inputQuantity;
        totalOutputs += outputQuantity;
        totalDelta += outputQuantity - inputQuantity;
        totalProcessTime += summary[groupKey].totalProcessTime || 0;
        data.push(row);
      });

      this.flatData = data;
      this.inputProducts = inputProducts;
      this.outputProducts = outputProducts;
      this.totalInputs = totalInputs;
      this.totalOutputs = totalOutputs;
      this.totalDelta = totalDelta;
      this.totalProcessTime = totalProcessTime;
      this.$emit("input", this.flatData);
    },
    calculateProcessTimeInHours(summary) {
      for (const pId in summary) {
        if (["inputProducts", "outputProducts"].includes(pId)) continue;
        const processSummary = summary[pId];
        let firstInputTime = null, lastOutputTime = null;
        for (const inputProduct in processSummary.inputs) {
          const details = processSummary.inputs[inputProduct];
          const productInputTime = details.processTimes.sort()[0];
          if (!firstInputTime || firstInputTime > productInputTime) {
            firstInputTime = productInputTime;
          }
        }
        for (const outputProduct in processSummary.outputs) {
          const details = processSummary.outputs[outputProduct];
          const productOutputTime = details.processTimes.sort().slice(-1)[0];
          if (!lastOutputTime || lastOutputTime < productOutputTime) {
            lastOutputTime = productOutputTime;
          }
        }
        processSummary.firstInputTime = firstInputTime;
        processSummary.lastOutputTime = lastOutputTime;
        if (firstInputTime && lastOutputTime) {
          const duration = moment.duration(moment(lastOutputTime).diff(moment(firstInputTime)));
          processSummary.totalProcessTime = duration.asMinutes();
        }
      }
    },
  },
};
</script>

<style scoped>
.totalCell {
  font-weight: bold;
}
</style>
<style>
.inventory-details thead.v-data-table-header > tr > th > div {
  display: inline-block;
  width: 80%;
}
.inventory-details thead.v-data-table-header > tr > th:first-child {
  min-width: 150px;
}
.inventory-details thead.v-data-table-header > tr > th {
  white-space: nowrap;
}
</style>
